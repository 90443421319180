import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import moment from "moment";

import { APIStatus } from "./APIStatus";

const initialState = {
  isLoading: "loading",
  devices: null,
  newLocation: null,
  newLocationError: null,
  editedLocation: null,
  editedLocationError: null,
  feedList: null,
  deviceDatas: null,
  feedData: null,
  pastData: null,
  pastDataError: null,
  previousDeviceData: null,
  previousDeviceDataError: null,
  previousStreamData: null,
  previousStreamDataError: null,
  locations: null,
  deviceLocations: null,
  allLocations: null,
  allLocationsError: null,
  bbLocations: null,
  bbLocationsError: null,
};

export const getAllLocations = createAsyncThunk(
  "getAllLocations",
  async (payload) => {
    const response = await axios.get(payload.url);
    return response;
  }
);

export const getBBLocations = createAsyncThunk(
  "getBBLocations",
  async (payload) => { 
    const response = await axios.get(payload.url);

    return response;
  }
);

export const getSiteDevices = createAsyncThunk(
  "getSiteDevices",
  async (payload) => {
    let orgdevicesObj = {};
    let organization = payload.organization;
    let isEnterpriseUser = payload.isEnterpriseUser;
    const response = axios.get(`/${organization}/devices`).then((res) => {
      let orgdevicesArr = [];
      if (res.data && res.data.results.length > 0) {
        if (isEnterpriseUser === "true") {
          orgdevicesArr = res.data.results;
        } else {
          orgdevicesArr = res.data.results.filter(
            (r) => r.organization_key === organization
          );
        }
      }
      orgdevicesObj = { results: orgdevicesArr };
      return orgdevicesObj;
    });
    return response;
  }
);

export const addSiteLocation = createAsyncThunk(
  "addSiteLocation",
  async (payload) => {
    let selectedOrg = payload.selectedOrg;
    let inputData = payload.inputData;
    const response = axios
      .post(`${selectedOrg}/locations`, inputData)
      .then((res) => {
        return res.data;
      });
    return response;
  }
);

export const editSiteLocation = createAsyncThunk(
  "editSiteLocation",
  async (payload) => {
    let locationKey = payload.locationKey;
    let inputData = payload.inputData;
    let selectedOrg = payload.selectedOrg;

    const response = axios
      .patch(`${selectedOrg}/locations/${locationKey}`, inputData)
      .then((res) => {
        return res.data;
      });
    return response;
  }
);

export const getSiteDeviceFeeds = createAsyncThunk(
  "getSiteDeviceFeeds",
  async (payload) => {
    let deviceId = payload.deviceId;
    const response = axios
      .get(
        `/${localStorage.getItem(
          "organization"
        )}/feeds?device__device_id=${deviceId}`
      )
      .then((res) => {
        return res.data;
      });
    return response;
  }
);

export const getSiteDeviceData = createAsyncThunk(
  "getSiteDeviceData",
  async (payload) => {
    let deviceId = payload.deviceId;
    let selectedDate = payload.selectedDate;
    let deviceOrg = payload.deviceOrg;

    var startDate = moment(selectedDate).format("YYYY-MM-DD");
    var endDate = moment(selectedDate).format("YYYY-MM-DD");
    var startTime = startDate + " 00:00";
    var endTime = endDate + " 23:59";

    var utcstartDate = moment(startTime).utc().format("YYYY-MM-DD HH:mm:ss");
    var utcendDate = moment(endTime).utc().format("YYYY-MM-DD HH:mm:ss");
    const response = axios
      .get(
        `/${deviceOrg}/devices/${deviceId}/device-data?completed_at__range=${utcstartDate}, ${utcendDate}`
      )
      .then((res) => {
        return res.data;
      });

    return response;
  }
);

export const getSiteFeedData = createAsyncThunk(
  "getSiteFeedData",
  async (payload) => {
    let deviceId = payload.deviceID;
    let deviceOrg = payload.deviceOrg;

    var startDate = moment().format("YYYY-MM-DD");
    var endDate = moment().format("YYYY-MM-DD");
    var startTime = startDate + " 00:00";
    var endTime = endDate + " 23:59";
    let mapData = [];
    var utcstartDate = moment(startTime).utc().format("YYYY-MM-DD HH:mm:ss");
    var utcendDate = moment(endTime).utc().format("YYYY-MM-DD HH:mm:ss");

    let offsetVal = 0;
    let response = axios
      .get(
        `/${deviceOrg}/devices/${deviceId}/stream-data-full?completed_at__range=${utcstartDate},${utcendDate}&limit=3000&offset=${offsetVal}`,
        { headers: { accept: "application/json" } }
      )
      .then((res) => {
        let totalCount = res.data.count;
        let noofLoops = totalCount / 3000;
        let streamdatareq = [];
        let streamdatares = [];
        streamdatares.push(res.data.results);
        if (noofLoops > 1) {
          let loopInt = Number(noofLoops.toFixed(0));
          let forloops = loopInt;

          for (let j = 0; j < forloops; j++) {
            offsetVal = offsetVal + 3000;
            streamdatareq.push(
              axios.get(
                `/${deviceOrg}/devices/${deviceId}/stream-data-full?completed_at__range=${utcstartDate},${utcendDate}&limit=3000&offset=${offsetVal}`
              )
            );
          }
          axios.all(streamdatareq).then(
            axios.spread((...streamResp) => {
              // streamResp.map((s) => {
              streamResp.forEach((s) => {
                streamdatares.push(s.data.results);
              });

              if (streamdatares.length > 1) {
                var allDataArr = [].concat.apply([], streamdatares);
                mapData = allDataArr;
                return mapData;
              }
            })
          );
        } else {
          mapData = res.data.results;
          return mapData;
        }
      });
    return response;
  }
);

export const getPreviousSiteData = createAsyncThunk(
  "getPreviousSiteData",
  async (payload) => {
    let deviceUUID = payload.deviceUUID;
    let device_id = payload.device_id;
    let selectedDate = payload.selectedDate;

    let deviceOrg = payload.deviceOrg;

    return axios
      .get(
        `/${deviceOrg}/location-processed-data?file_created_at=${selectedDate}&device__device_id=${device_id}`
      )
      .then((res) => {
        let tData = res.data.results.filter((t) => {
          if (
            t.file_type === "stream_data" ||
            t.file_type === "device_data" ||
            t.file_type === "device_summary" ||
            t.file_type === "device_session_summary"
          ) {
            if (t.device) {
              if (t.device === deviceUUID) return true;
            } else {
              if (t.file_name.split("_")[0] === device_id) return true;
            }
          }
          return false;
        });
        return tData;
      });
  }
);

export const getPreviousSiteDeviceData = createAsyncThunk(
  "getPreviousSiteDeviceData",
  async (payload) => {
    let itemId = payload.itemId;

    const response = axios
      .get(
        `/${localStorage.getItem(
          "organization"
        )}/location-processed-data/${itemId}`
      )
      .then((res) => {
        return res.data;
      });
    return response;
  }
);

export const getPreviousSiteStreamData = createAsyncThunk(
  "getPreviousSiteStreamData",
  async (payload) => {
    let itemId = payload.itemId;
    let selectedOrg = payload.selectedOrg;

    const response = axios
      .get(`/${selectedOrg}/location-processed-data/${itemId}`)
      .then((res) => {
        return res.data;
      });
    return response;
  }
);

export const getSiteLocations = createAsyncThunk(
  "getSiteLocations",
  async (payload) => {
    let offset = payload.offset;
    let search = payload.search;
    let selectedOrg = payload.selectedOrg;

    let offsetValue = offset * 15;
    let apiEndPoint = "";

    if (search == null) {
      apiEndPoint = `/${selectedOrg}/locations?limit=15&offset=${offsetValue}`;
    } else {
      apiEndPoint = `/${selectedOrg}/locations?key=${search}`;
    }

    const response = axios.get(apiEndPoint).then((res) => {
      let locations = {
        length: res.data.count,
        data: res.data.results.features.map((item, index) => {
          if (item.bbox && item.bbox.length === 4) {
            item.properties.cornersLatLng = [
              [item.bbox[0], item.bbox[1]],
              [item.bbox[2], item.bbox[3]],
            ];
          }
          return {
            ...item,
          };
        }),
      };
      return locations;
    });
    return response;
  }
);

export const getSiteDeviceLocations = createAsyncThunk(
  "getSiteDeviceLocations",
  async (payload) => {
    let search = payload.search;
    let selectedOrg = payload.selectedOrg;

    const response = axios
      .get(`/${selectedOrg}/locations?key=${search}`)
      .then((res) => {
        let locations = res.data.results.features.map((item, index) => {
          let coordinates = item.geometry.coordinates;

          if (item.bbox && item.bbox.length === 4) {
            item.properties.cornersLatLng = [
              [item.bbox[0], item.bbox[1]],
              [item.bbox[2], item.bbox[3]],
            ];
          }
          return {
            ...item,
            type: "Feature",
            // properties: item,
            geometry: {
              type: item.geometry.type, //'Polygon',
              coordinates: coordinates,
            },
          };
        });

        return locations;
      });

    return response;
  }
);

const setbackManagementSlice = createSlice({
  name: "sites",
  initialState,
  reducers: {
    reset(state, action) {
      state.devices = null;
      state.devicesMetas = null;
    },
    resetAckData(state, action) {
      state.acknowledgeReceipt = null;
      state.acknowledgeError = null;
    },
    clearDeviceLocations(state, action) {
      state.deviceLocations = null;
    },
    clearDetailData(state, action) {
      state.feedData = null;
      state.pastData = null;
      state.previousStreamData = null;
      state.previousDeviceData = null;
      state.deviceLocations = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSiteDevices.pending, (state, action) => {
        state.isLoading = APIStatus.loading;
      })
      .addCase(getSiteDevices.fulfilled, (state, action) => {
        state.devices = action.payload;
        state.isLoading = APIStatus.loaded;
      })
      .addCase(getSiteDevices.rejected, (state, action) => {
        state.isLoading = APIStatus.failed;
      })

      .addCase(addSiteLocation.pending, (state, action) => {
        state.isLoading = APIStatus.loading;
      })
      .addCase(addSiteLocation.fulfilled, (state, action) => {
        state.newLocation = action.payload;
        state.isLoading = APIStatus.loaded;
      })
      .addCase(addSiteLocation.rejected, (state, action) => {
        state.newLocationError = action.payload;
        state.isLoading = APIStatus.failed;
      })
      .addCase(editSiteLocation.pending, (state, action) => {
        state.isLoading = APIStatus.loading;
      })
      .addCase(editSiteLocation.fulfilled, (state, action) => {
        state.editedLocation = action.payload;
        state.isLoading = APIStatus.loaded;
      })
      .addCase(editSiteLocation.rejected, (state, action) => {
        state.editedLocationError = action.payload;
        state.isLoading = APIStatus.failed;
      })
      .addCase(getSiteDeviceFeeds.pending, (state, action) => {
        state.isLoading = APIStatus.loading;
      })
      .addCase(getSiteDeviceFeeds.fulfilled, (state, action) => {
        state.feedList = action.payload;
        state.isLoading = APIStatus.loaded;
      })
      .addCase(getSiteDeviceFeeds.rejected, (state, action) => {
        state.isLoading = APIStatus.failed;
      })
      .addCase(getSiteDeviceData.pending, (state, action) => {
        state.isLoading = APIStatus.loading;
      })
      .addCase(getSiteDeviceData.fulfilled, (state, action) => {
        state.deviceDatas = action.payload;
        state.isLoading = APIStatus.loaded;
      })
      .addCase(getSiteDeviceData.rejected, (state, action) => {
        state.isLoading = APIStatus.failed;
      })
      .addCase(getSiteFeedData.pending, (state, action) => {
        state.isLoading = APIStatus.loading;
      })
      .addCase(getSiteFeedData.fulfilled, (state, action) => {
        state.feedData = action.payload;
        state.isLoading = APIStatus.loaded;
      })
      .addCase(getSiteFeedData.rejected, (state, action) => {
        state.feedDataError = action.data;
        state.isLoading = APIStatus.failed;
      })
      .addCase(getPreviousSiteData.pending, (state, action) => {
        state.isLoading = APIStatus.loading;
      })
      .addCase(getPreviousSiteData.fulfilled, (state, action) => {
        state.pastData = action.payload;
        state.isLoading = APIStatus.loaded;
      })
      .addCase(getPreviousSiteData.rejected, (state, action) => {
        state.pastDataError = action.payload;
        state.isLoading = APIStatus.failed;
      })
      .addCase(getPreviousSiteDeviceData.pending, (state, action) => {
        state.isLoading = APIStatus.loading;
      })
      .addCase(getPreviousSiteDeviceData.fulfilled, (state, action) => {
        state.previousDeviceData = action.payload;
        state.isLoading = APIStatus.loaded;
      })
      .addCase(getPreviousSiteDeviceData.rejected, (state, action) => {
        state.previousDeviceDataError = action.payload;
        state.isLoading = APIStatus.failed;
      })
      .addCase(getPreviousSiteStreamData.pending, (state, action) => {
        state.isLoading = APIStatus.loading;
      })
      .addCase(getPreviousSiteStreamData.fulfilled, (state, action) => {
        state.previousStreamData = action.payload;
        state.isLoading = APIStatus.loaded;
      })
      .addCase(getPreviousSiteStreamData.rejected, (state, action) => {
        state.previousStreamDataError = action.payload;
        state.isLoading = APIStatus.failed;
      })
      .addCase(getSiteLocations.pending, (state, action) => {
        state.isLoading = APIStatus.loading;
      })
      .addCase(getSiteLocations.fulfilled, (state, action) => {
        state.locations = action.payload;
        state.isLoading = APIStatus.loaded;
      })
      .addCase(getSiteLocations.rejected, (state, action) => {
        state.isLoading = APIStatus.failed;
      })
      .addCase(getSiteDeviceLocations.pending, (state, action) => {
        state.isLoading = APIStatus.loading;
      })
      .addCase(getSiteDeviceLocations.fulfilled, (state, action) => {
        state.deviceLocations = action.payload;
        state.isLoading = APIStatus.loaded;
      })
      .addCase(getSiteDeviceLocations.rejected, (state, action) => {
        state.isLoading = APIStatus.failed;
      })
      .addCase(getAllLocations.fulfilled, (state, action) => {
        state.allLocations = action.payload.data;
        state.isLoading = APIStatus.loaded;
      })
      .addCase(getAllLocations.rejected, (state, action) => {
        state.allLocationsError = action.payload;
        state.isLoading = APIStatus.failed;
      })
      .addCase(getBBLocations.fulfilled, (state, action) => {
        state.bbLocations = action.payload.data;

        state.isLoading = APIStatus.loaded;
      })
      .addCase(getBBLocations.rejected, (state, action) => {
        state.bbLocationsError = action.payload;

        state.isLoading = APIStatus.failed;
      });
  },
});

export const { load, resetAckData, clearDeviceLocations, clearDetailData } =
  setbackManagementSlice.actions;

export default setbackManagementSlice.reducer;
