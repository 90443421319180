import React from "react";
import axios from 'axios';
import { formatErrorMessage } from "../util/util";

var UserStateContext = React.createContext();
var UserDispatchContext = React.createContext();

function userReducer(state, action) {
  switch (action.type) {
    case "LOGIN_SUCCESS":
      return { ...state, isAuthenticated: true };
    // case "LOGIN_FAILURE":
    //   return { ...state, isAuthenticated: false };
    case "SIGN_OUT_SUCCESS":
      return { ...state, isAuthenticated: false };
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function UserProvider({ children }) {
  var [state, dispatch] = React.useReducer(userReducer, {
    isAuthenticated: !!localStorage.getItem("id_token"),
  });

  return (
    <UserStateContext.Provider value={state}>
      <UserDispatchContext.Provider value={dispatch}>
        {children}
      </UserDispatchContext.Provider>
    </UserStateContext.Provider>
  );
}

function useUserState() {
  var context = React.useContext(UserStateContext);
  if (context === undefined) {
    throw new Error("useUserState must be used within a UserProvider");
  }
  return context;
}

function useUserDispatch() {
  var context = React.useContext(UserDispatchContext);
  if (context === undefined) {
    throw new Error("useUserDispatch must be used within a UserProvider");
  }
  return context;
}

export { UserProvider, useUserState, useUserDispatch, loginUser, authenticateEnterpriseUser, signOut };

// ###########################################################
function authenticateEnterpriseUser(dispatch, token, org, user, props) {

  let userName = user
  let orgName = org
  let oldToken = token

  let refreshPayload = {
    token: oldToken
  }
  if (userName && orgName && oldToken) {
    axios.post('/refresh-jwt-token', refreshPayload).then(res => {
      if (res && res.status && res.status === 200) {
        axios.defaults.headers.common["Content-Type"] = "application/json";
        axios.defaults.headers.common["Authorization"] = "JWT " + res.data.token;
        localStorage.setItem('id_token', res.data.token)
        axios.get(`/${orgName}/users/${userName}`).then(resp => {
          if (resp && resp.data && resp.data.user) {
            localStorage.setItem('organization', orgName)
            localStorage.setItem('first_name', resp.data.first_name)
            localStorage.setItem('last_name', resp.data.last_name)
            localStorage.setItem('user', resp.data.user)
            localStorage.setItem('email', resp.data.user)
            localStorage.setItem('isEnterpriseUser', resp.data.user.is_enterprise_user)
            localStorage.setItem('isOrgRouted', 'false')
            localStorage.setItem('isSalAdmin', resp.data.is_saladmin)

            // if(resp.data.is_enterprise_user === true){
            //   localStorage.setItem('enterpriseOrganization', resp.data.user.organization)
            // }   
            if (props.match.params) {
              if (props.match.params.dashboardType) {
                if (props.match.params.deviceId) {
                  props.history.push({ pathname: `/app/${props.match.params.dashboardType}/${props.match.params.deviceId}` })
                } else {
                  props.history.push({ pathname: `/app/${props.match.params.dashboardType}` })
                }
              } else {
                props.history.push({ pathname: "/" })
              }
            }
            dispatch({ type: 'LOGIN_SUCCESS' })
          }
        })
      }
    }).catch(err => {
      dispatch({ type: "SIGN_OUT_SUCCESS" });
    })
  }
}

function loginUser(dispatch, login, password, history, setIsLoading, setIsError, setIsSuccess, setDisplayMessage) {
  setDisplayMessage([]);
  setIsError(false);
  setIsSuccess(false);
  setIsLoading(true);
  if (!!login && !!password) {
    const user = {
      username: login,
      password: password
    };
    axios.post(`/login`, user)
      .then(res => {
        if (res.data.user.organization === "geofencing" || res.data.user.organization === "geofencing_internal") {
          localStorage.setItem('id_token', res.data.token)
          localStorage.setItem('organization', res.data.user.organization)
          localStorage.setItem('first_name', res.data.user.first_name)
          localStorage.setItem('last_name', res.data.user.last_name)
          localStorage.setItem('email', res.data.user.email)
          localStorage.setItem('isEnterpriseUser', res.data.user.is_enterprise_user)
          localStorage.setItem('isOrgRouted', 'false')
          localStorage.setItem('isSalAdmin', res.data.user.is_saladmin)

          if (res.data.user.is_enterprise_user === true) {
            localStorage.setItem('enterpriseOrganization', res.data.user.organization)
          }
          setDisplayMessage(["Login Success"]);
          setIsError(false);
          setIsSuccess(true);
          setIsLoading(false)
          axios.defaults.headers.common["Authorization"] = "JWT " + res.data.token;
          dispatch({ type: 'LOGIN_SUCCESS' })
          window.gtag("event", "login_success", {});
        } else {
          setDisplayMessage(["Login Failed, UnAuthorized Org Credentials"]);
          setIsError(true);
          setIsSuccess(false);
          setIsLoading(false);
          history.push("/login");
        }
      }).catch(err => {
        const errorMessage = formatErrorMessage(err.response.data);
        setDisplayMessage(errorMessage);
        setIsError(true);
        setIsSuccess(false);
        setIsLoading(false);
      });
  } else {
    dispatch({ type: "LOGIN_FAILURE" });
    setDisplayMessage(["Login Failed, Invalid Credentials"]);
    setIsError(true);
    setIsSuccess(false);
    setIsLoading(false);
  }
}

function signOut(dispatch, history) {
  localStorage.removeItem("id_token");
  localStorage.removeItem("organization");
  localStorage.removeItem("first_name");
  localStorage.removeItem("last_name");
  localStorage.removeItem("email");
  localStorage.removeItem("user");
  localStorage.removeItem("isSalAdmin");
  localStorage.removeItem("isEnterpriseUser");
  localStorage.removeItem("enterpriseOrgs");
  localStorage.removeItem("isOrgRouted");
  localStorage.removeItem("enterpriseOrganization");
  localStorage.removeItem("dashboard_config");
  axios.defaults.headers.common["Authorization"] = "";
  dispatch({ type: "SIGN_OUT_SUCCESS" });
  history.push("/login");
}
