import React from "react";
import PUBLogo from "./PUBLogo.png";
import useStyles from "./styles";
import { withRouter } from "react-router-dom";
import {
  Grid,
  Tabs,
  Tab,
  TextField,
  CircularProgress,
  Button,
  InputAdornment
} from "@material-ui/core";
import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { resetPasswordEmail } from "../../app/resetPasswordSlice";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import InfoTooltip from "../../components/InfoTooltip";
import { formatErrorMessage, validatePasswords, validatePasswordOne } from "../../util/util";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const ResetPassword = () => {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [activeTabId, setActiveTabId] = useState(0);
  const [passwordOne, setPasswordOne] = useState("");
  const [passwordTwo, setPasswordTwo] = useState("");
  // const [isValidPasswordOne, setIsValidPasswordOne] = useState(false);
  // const [isValidPasswordTwo, setIsValidPasswordTwo] = useState(false);
  const [isSubmittable, setIsSubmittable] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [displayMessage, setDisplayMessage] = useState([]);
  useEffect(() => {
    document.title = `Geofence - Reset Password`;
  });

  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();

  const resetPasswordEmailError = useSelector(
    (state) => state.resetPasswordSlice.resetPasswordEmailError
  );

  const resetPasswordEmailSuccess = useSelector(
    (state) => state.resetPasswordSlice.resetPasswordEmail
  );

  useEffect(() => {
    if (resetPasswordEmailError && isLoading) {
      setIsSuccess(false);
      setIsError(true);
      const errorMessage = formatErrorMessage(resetPasswordEmailError);
      setDisplayMessage(errorMessage);
      setIsLoading(false);
    }
    if (resetPasswordEmailSuccess && isLoading) {
      setIsError(false);
      setIsSuccess(true);
      setDisplayMessage([`${resetPasswordEmailSuccess} Navigating to Login Screen`]);
      setIsLoading(false);
      setTimeout(() => {
        history.push({
          pathname: `/login`,
        });
      }, 3000)
    }
    // eslint-disable-next-line
  }, [resetPasswordEmailError, resetPasswordEmailSuccess]);

  useEffect(() => {
    const validPasswordOne = validatePasswordOne(passwordOne);
    const validPasswordTwo = validatePasswords(passwordOne, passwordTwo);
    const validPasswordFields = validPasswordOne && validPasswordTwo;
    // if (!validPasswordOne) {
    //   setIsValidPasswordOne(false);
    //   setIsValidPasswordTwo(true);
    // } else if (!validPasswordTwo) {
    //   setIsValidPasswordTwo(false);
    //   setIsValidPasswordOne(true);
    // } else {
    //   setIsValidPasswordOne(true);
    //   setIsValidPasswordTwo(true);
    // }
    setIsSubmittable(validPasswordFields);
  }, [passwordOne, passwordTwo]);

  let locationInfo = location.pathname.split("/");
  let UID = locationInfo[3];
  let token = locationInfo[5];

  const vertical = "top";
  const horizontal = "right";

  const handlePasswordReset = () => {
    setIsLoading(true);
    dispatch(
      resetPasswordEmail({
        new_password1: passwordOne,
        new_password2: passwordTwo,
        uid: UID,
        token: token,
      })
    );
  };

  return (
    <div
      style={{
        display: "flex",
        height: "100vh",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div>
        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          open={isError}
          autoHideDuration={3000}
        >
          <Alert severity="error">{displayMessage.map((msg) => <div>{msg}</div>)}</Alert>
        </Snackbar>
        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          open={isSuccess}
          autoHideDuration={3000}
        >
          <Alert severity="success">{displayMessage.map((msg) => <div>{msg}</div>)}</Alert>
        </Snackbar>
      </div>

      <Grid container className={classes.container}>
        <Grid className={classes.logotypeContainer}>
          <img src={PUBLogo} alt="logo" className={classes.logoImage} />
        </Grid>
        <Grid className={classes.formContainer}>
          <Tabs
            value={activeTabId}
            onChange={(e, id) => setActiveTabId(id)}
            indicatorColor="primary"
            textColor="primary"
            centered
            style={{ marginBottom: "10%" }}
          >
            <Tab label="Reset Your Password" classes={{ root: classes.tab }} />
          </Tabs>
          <TextField
            style={{ width: "100%" }}
            // error={!isValidPasswordOne}
            id="passwordOne"
            InputProps={{
              endAdornment: (
                // <InputAdornment position="end" className={!isValidPasswordOne && classes.invalidField}>
                <InputAdornment position="end">
                  {<InfoTooltip contentKey="newPassword" />}
                </InputAdornment>
              ),
              classes: {
                underline: classes.textFieldUnderline,
                input: classes.textField,
              },
            }}
            value={passwordOne}
            onChange={(e) => setPasswordOne(e.target.value)}
            margin="normal"
            placeholder="New Password"
            type="password"
          />
          <TextField
            style={{ width: "100%" }}
            // error={!isValidPasswordTwo}
            id="passwordTwo"
            InputProps={{
              endAdornment: (
                // <InputAdornment position="end" className={!isValidPasswordTwo && classes.invalidField}>
                <InputAdornment position="end">
                  {<InfoTooltip contentKey="confirmPassword" />}
                </InputAdornment>
              ),
              classes: {
                underline: classes.textFieldUnderline,
                input: classes.textField,
              },
            }}
            value={passwordTwo}
            onChange={(e) => setPasswordTwo(e.target.value)}
            margin="normal"
            placeholder="Re-Enter Password"
            type="password"
          />
          <div className={classes.formButtons}>
            {isLoading ? (
              <CircularProgress size={26} className={classes.loginLoader} />
            ) : (
              <>
                <Button
                  disabled={!isSubmittable}
                  onClick={() => {
                    handlePasswordReset();
                  }}
                  variant="contained"
                  color="primary"
                  size="large"
                >
                  Reset Password
                </Button>
                <Button
                  onClick={() => {
                    history.push({
                      pathname: `/login`,
                    });
                  }}
                  variant="contained"
                  color="primary"
                  size="large"
                  style={{ marginLeft: "50px" }}
                >
                  Cancel
                </Button>
              </>
            )}
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default withRouter(ResetPassword);
