import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { APIStatus } from "./APIStatus";

const initialState = {
  alarms: null,
  activeAlarms: null,
  deviceFeedAlarms: null,
  isLoading: "loading",
  acknowledgeStatus: APIStatus.idle,
  acknowledgeReceipt: null,
  acknowledgeError: null,
  ackCommentsStatus: APIStatus.idle,
  ackCommentsReceipt: null,
  ackCommentsError: null,
};

export const getAlarms = createAsyncThunk("getAlarms", async (payload) => {
  const response = await axios.get(payload.url);
  return response;
});

export const getAlarmsCSV = async (url) => {
  const response = await axios.get(url, {
    headers: { 'Content-Type': 'text/csv', 'accept': 'text/csv, text/plain, */*' }
  }).then((resp) => resp).catch((error) => {
    return null;
  });
  return response;
};

export const getActiveAlarms = createAsyncThunk(
  "getActiveAlarms",
  async (payload) => {
    const response = await axios.get(payload.url);
    return response;
  }
);

export const getDeviceFeedAlarms = createAsyncThunk(
  "getDeviceFeedAlarms",
  async (payload) => {
    const response = await axios.get(payload.url);
    return response;
  }
);

export const acknowledgeAlarm = createAsyncThunk(
  "acknowledgeAlarm",
  async (payload) => {
    const response = await axios
      .post(`/${payload.organization}/comments`, payload.comment)
      .then((resp) => {
        let commentsResp = axios
          .get(
            `/${localStorage.getItem("organization")}/comments?device=${payload.deviceId
            }&limit=1`
          )
          .then((res) => {
            let commentId =
              res.data.results.length > 0 ? res.data.results[0].id : "";
            let commentsJson = {
              alarmNotes: payload.commentText,
              commentId: commentId,
            };
            let ackData = {
              note: JSON.stringify(commentsJson),
            };
            let ackResp = axios
              .post(
                `/${localStorage.getItem("organization")}/alarms/${payload.alarmId
                }/acknowledge`,
                ackData
              )
              .then((resData) => {
                return resData.data;
              });
            return ackResp;
          });
        return commentsResp;
      });
    return response;
  }
);

export const getAcknowlegedComments = createAsyncThunk(
  "getAcknowlegedComments",
  async (payload) => {
    let reqArr = [];
    let alarmTypeList = [];
    payload.acknowledgements &&
      payload.acknowledgements.forEach((ack) => {
        let d = ack.note;
        if (d.includes('{"alarmNotes"') && d.includes('"commentId"')) {
          d = JSON.parse(d);
        }
        if (d && d.commentId) {
          alarmTypeList.push(d[0]);
          reqArr.push(
            axios.get(
              `/${localStorage.getItem("organization")}/comment/${d.commentId}`
            )
          );
        }
      });
    const response = axios.all(reqArr).then(
      axios.spread((...responses) => {
        let responseData = responses.map((d, i) => {
          return { ...d.data, selectedAlarmType: alarmTypeList[i] };
        });

        return responseData;
      })
    );
    return response;
  }
);

const alarmsSlice = createSlice({
  name: "devices",
  initialState,
  reducers: {
    reset(state, action) {
      state.devices = null;
      state.devicesMetas = null;
    },
    resetAckData(state, action) {
      state.acknowledgeReceipt = null;
      state.acknowledgeError = null;
    },
    resetAlarms(state, action) {
      state.alarms = null;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAlarms.pending, (state, action) => {
        state.isLoading = APIStatus.loading;
      })
      .addCase(getAlarms.fulfilled, (state, action) => {
        state.isLoading = APIStatus.loaded;
        state.alarms = action.payload.data;
      })
      .addCase(getActiveAlarms.pending, (state, action) => {
        state.isLoading = APIStatus.loading;
      })
      .addCase(getActiveAlarms.fulfilled, (state, action) => {
        state.isLoading = APIStatus.loaded;
        state.activeAlarms = action.payload.data.results;
      })
      .addCase(getDeviceFeedAlarms.pending, (state, action) => {
        state.isLoading = APIStatus.loading;
      })
      .addCase(getDeviceFeedAlarms.fulfilled, (state, action) => {
        state.isLoading = APIStatus.loaded;
        state.deviceAlarms = action.payload.data.results;
      })
      .addCase(acknowledgeAlarm.pending, (state, action) => {
        state.acknowledgeStatus = APIStatus.loading;
      })
      .addCase(acknowledgeAlarm.fulfilled, (state, action) => {
        state.acknowledgeStatus = APIStatus.loaded;
        state.acknowledgeReceipt = action.payload;
      })
      .addCase(acknowledgeAlarm.rejected, (state, action) => {
        state.acknowledgeStatus = APIStatus.failed;
        state.acknowledgeError = action.error;
      })
      .addCase(getAcknowlegedComments.pending, (state, action) => {
        state.ackCommentsStatus = APIStatus.loading;
      })
      .addCase(getAcknowlegedComments.fulfilled, (state, action) => {
        state.ackCommentsStatus = APIStatus.loaded;
        state.ackCommentsReceipt = action.payload;
      })
      .addCase(getAcknowlegedComments.rejected, (state, action) => {
        state.ackCommentsStatus = APIStatus.failed;
        state.ackCommentsError = action.error;
      });
  },
});

export const { load, resetAckData, resetAlarms } = alarmsSlice.actions;

export default alarmsSlice.reducer;
