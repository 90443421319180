export const helptext = {
  /* Add or Edit Device Form */
  DeviceName: "Unique ID of rEye device on device enclosure. e.g.: SAL_3F6A1B.",
  DeviceId: "Last 6 characters of Device Name. e.g.:3F6A1B.",
  DeviceType: "Input type SSAI- Analog, SSAR- RTD, SSRI- RS485, SNRI- NBIoT.",
  AliasName: "USER defined. Not to exceed 15 characters.",
  SerialNumber: "Serial Number on device enclosure.",
  Location: "Installed Location.  e.g: Pump room 1, Chiller 3.",
  Latitude: "Enter referring to Google Maps at Max. zoom.  e.g.: 1.291",
  Longitude: "Enter referring to Google Maps at Max. zoom.  e.g.: 103.785",
  ActivityTimeout:
    "Time (in seconds), beyond which the device will go to Inactive state if no data is acquired.",
  isGpsCapable: "Device can track GPS",
  isGpsEnable: "Device GPS Enable / Disable",

  /* Add or Edit Feeds Form  */
  FeedName:
    "Enter parameter to measure. (15 characters and No Special Characters).",
  InputParameter: "Choose the appropriate input parameter from list.",
  FeedUnit:
    "Enter unit of measurement e.g.:Volt,Amp,kW,watt,degC,us(micro second),m/sec etc.",
  FeedCategory: "NATIVE (actual value), COMPUTED (use formula) or GPS.",
  Formula: "Enter the required formula to compute feed value.",
  FeedType:
    'Choose FEED type from list. Choosing "Energy" will enable metering dashboard.',
  ConversionFactor:
    "Feed Value multiplied by fraction e.g.: 0.1, 0.01 etc. If no conversion factor needed just enter 1.",
  PreviousData:
    "‘Yes’ to view the data recorded previously as part of this new feed (in case old feed was deleted)",
  FeedOffset: "Enter the Feed Offset",
  FeedTags: "Enter the Feed Tags",
  isFeedVisible:
    "‘Yes’ for the feed to be visible and No for hiding the feed in Feeds & Alarms dashboard",
  CustomText: "Enter a value",
  /* Add or Edit Rule Form  */
  Category:
    "NORMAL ,WARNING – detrimental events, MINOR – trivial events, MAJOR – significant events",
  RuleName: "Enter a rule name.",
  FeedForRule: "Select the associated feed for the rule.",
  RuleType: "Select as per requirement. ‘Equal’ for a digital input",
  Arg1: "Enter the value to compare with feed based on rule type defined to generate an alert/notification. e.g.: 1 or 0 for a digital input or any other value",
  Arg2: "Setting a range(Arg1 upper range & Arg2 Lower Range) for alert / notification",
  Priority: "Enter if multiple rules prevail for same feed. e.g.: 1,2,3 etc.",
  DisabledStart: "Duration from which the rule is forced to be disabled.",
  DisabledEnd: "Duration till which the rule is forced to be disabled.",
  CountDelay: "Enable/Disable Alarm Delay",
  CountDelayNumber: "Number of minutes to delay.",
  /* Add or Edit Trigger Form  */
  TriggerName:
    "Enter the trigger name based on the application in not more than 15 characters.",
  TriggerType: "Select e-mail, SMS or WhatsApp. ",
  TriggerAlarmType: "Alarm Notification to be sent for Feed or Device Alarm ",
  MinimumCategory: "Trigger category for feed to send notification",
  Subscribers:
    "Enter recipient’s valid email ID or Phone number (with country code) and press Enter",
  Disabled_Start: "Duration from which the trigger is forced to disabled.",
  Disabled_End: "Duration till which the trigger is forced to disabled ",
  Devices: "Choose Devices to send Alarm ",
  IsEnabled: "‘Yes’ to enable or ‘No’ to disable Notification / Report",
  IsCleared: "Alarm CLEARED notification sent only if set to ‘Yes’",
  /* Profile Settings Form  */
  FirstName: "Enter First Name",
  LastName: "Enter Last Name",
  Email: "Email ID (Read only)",
  CompanyName: "Organisation Name (Read only)",
  Homepage: "Choose Home Dashboard",
  /* Custom Dashboard Form  */
  widgetNameinfo: "Enter widget name ",
  widgetTypeinfo: "Required graphical representation",
  counterinfo: "Select what to display in text format",
  boxplotinfo: "Single or Multiple feed",
  wiseinfo: "Duration",
  operationinfo: "Mathematical operation to be performed",
  activeindicatorLabel: "Enter indicator description",
  inactiveindicatorLabel: "Enter indicator description",
  descriptionLabel: "Enter indicator description",
  activeindicationColor: "Choose indicator color for active state",
  inactiveindicationColor: "Choose indicator color for inactive state",
  deviceDatainfo: "Choose required device (source) to be displayed",
  sourceDatainfo: "Choose required feed (source) to be displayed",
  datasourceinfo: "Choose the required feed (source) to be displayed",
  scaleValueinfo: "Time period to display data points",
  startTimeinfo: "Data points display Start time",
  endTimeinfo: "Choose the required End time",
  thresholdinfo: "Enter threshold feed value to display in graph ",
  mininfo: "Enter minimum feed value to display in graph ",
  maxinfo: "Enter maximum feed value to display in graph ",
  /* Add or Edit Report Form  */
  reportName: "Enter the report name based on the application ",
  reportType: "Choose Weekly or Custom. ",
  protocol: "Select e-mail, SMS or WhatsApp. ",
  Subs: "Enter recipient’s valid email ID or Phone number (with country code) and press Enter",
  Time: "Enter the time to receive the report",
  Date: "Enter the date to receive the report",
  Day: "Select day to receive the report",
  Frequency: "Choose FREQUENCY to receive the reports",
  DeviceList: "Choose DEVICES for report",
  FeedList: "Choose FEEDS for report",
  isEnabled: "‘Yes’ to enable or ‘No’ to disable Notification / Report",
  RepeatEnabled:
    "‘Yes’ to enable sending reports on an specified interval or ‘No’ to disable sending reports reports on an specified interval",
  RepeatInterval: "Choose the repeat interval duration for sending reports",

  /* Add or Edit Device Group Form  */
  GroupName: "Enter Name for the Device Group ",
  GroupDesc: "Enter Description",
  GroupDevices: "Select Devices to Add into Group ",

  /* Change Password Form  */
  OldPassword: "Enter Old Password",
  NewPassword: "Enter New Password",
  ReenterPassword: "Confirm New Password ",
  MeterDeviation:
    "Deviation represents % change in value from previous time period",

  /* Add or Edit rEyelite Config Form  */
  Datasource: "Choose the required feed (source) to be displayed",
  DisplayName: "Enter the name to be displayed on rEyeLite app",

  /* Add or Edit Asset in Geo Fencing*/
  AssetName: "Enter the name of the asset",
  AssetId: "Enter Id of the asset",
  AssetType: "Enter the Type of asset",
  ProjectReferenceNumber: "Enter the Project Reference Number",
  ProjectTitle: "Enter Project Title",
  ContractorCompany: "Enter the Name of Contractor company",
  ContractorName: "Enter the Name of Contractor",
  ContractorPhoneNo: "Enter the Phone number of Contractor",
  ContractorEmailId: "Enter the eMail id of Contractor",
  ProjRefNo: "Enter the Project Reference Number",
  ProjTitle: "Enter the Project Title",
  POWSRefNo: "Enter the Pows Reference Number",
  AssetLocation: "Enter the Asset Location",
  RigId: "Enter the Rig Id",
  AssetDevices: "Select the Devices to add to Asset",
  IsAssetActive: "Toggle current status of Asset",
  RigType:"Select the Type of Rig",
  SelAssetType:"Select the Asset type", 
  contactPersonName:"Enter Contact Person Name",
  contactPersonPhone :"Enter Contact Person Phone No",
  contactPersonEmail:"Enter Contact Person Email",
  powsApprovalRefNumber:"Enter POWS Approval Reference Number",
  projectRefNumber:"Enter Project Reference Number",
  WorkStartDate:"Select Work Start Date",
  pipeTypes:"Select Types of Pipes Affected",
contractorId:"Enter Contractor ID",
workSiteId:"Enter Work Site ID",

  currentPassword: <>Current Password cant be empty.<br />Current Password and New Password cant be the same.</>,
  newPassword: <>Please Enter a Password with atleast 12 Characters.<br />Requires at least two of the following: uppercase letters (A-Z), lowercase letters (a-z), digits (0-9) or special character (@).</>,
  confirmPassword: <>Re-Password must be same as New Password</>,
};
