import React, { useState, useEffect } from "react";
import {
  Grid,
  CircularProgress,
  Typography,
  Button,
  Tabs,
  Tab,
  TextField,
} from "@material-ui/core";
import { withRouter } from "react-router-dom";
import useStyles from "./styles";
import smalllogo from "./SpaceAge-Icon-Grayscale.png";
import PUBLogo from "./PUBLogo.png";
import siRigImage from "./SIrig.jpg";
import pilingRigImage from "./Pilingrig.png";
import { useUserDispatch, loginUser } from "../../context/UserContext";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import { resetUserPassword } from "../../app/resetPasswordSlice";
import { useSelector, useDispatch } from "react-redux";
import { formatErrorMessage } from "../../util/util";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
function Login(props) {
  const classes = useStyles();
  const userDispatch = useUserDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [displayMessage, setDisplayMessage] = useState([]);
  const [activeTabId, setActiveTabId] = useState(0);
  const [loginValue, setLoginValue] = useState("");
  const [passwordValue, setPasswordValue] = useState("");
  const [forgotPasswordStatus, setForgotPasswordStatus] = useState(false);
  const [resetPasswordEmail, setResetPasswordEmail] = useState("");
  //const [resetPasswordOrganization, setResetPasswordOrganization] = useState("");

  const vertical = "top";
  const horizontal = "right";

  const dispatch = useDispatch();

  useEffect(() => {
    document.title = `Geofence - Login`;
  });

  const resetPasswordSuccess = useSelector(
    (state) => state.resetPasswordSlice.resetPassword
  );

  const resetPasswordError = useSelector(
    (state) => state.resetPasswordSlice.resetPasswordError
  );

  useEffect(() => {
    if (resetPasswordSuccess && isLoading) {
      setDisplayMessage([resetPasswordSuccess]);
      setIsError(false);
      setIsSuccess(true);
      setIsLoading(false);
    }
    if (resetPasswordError && isLoading) {
      const errorMessage = formatErrorMessage(resetPasswordError);
      setDisplayMessage(errorMessage);
      setIsError(true);
      setIsSuccess(false);
      setIsLoading(false);
    }
    // eslint-disable-next-line
  }, [resetPasswordSuccess, resetPasswordError]);

  return (
    <Grid container className={classes.container}>
      <Grid
        className={classes.logotypeContainer}
        item
        lg={7}
        md={7}
        sm={6}
        xs={12}
      >
        <img
          src={smalllogo}
          alt="logo"
          className={classes.smalllogotypeImage}
        />
        <div>
          <Typography
            variant="h5"
            weight="bold"
            style={{
              fontSize: 40,
              fontFamily: "Rubik",
              textAlign: "center",
              color: "#daefff",
            }}
            className={classes.logotype}
          >
            {" "}
            Geofencing{" "}
          </Typography>
          <Typography
            variant="h5"
            weight="bold"
            style={{
              marginTop: 15,
              fontSize: 18,
              fontFamily: "Rubik",
              textAlign: "center",
              color: "#ffffff",
            }}
            className={classes.logotype}
          >
            {" "}
            for Public Sewerage and Water Supply Networks{" "}
          </Typography>
        </div>
        <div className={classes.rigImageParent}>
          <img
            src={pilingRigImage}
            alt="logo"
            className={classes.rigImage}
          />
          <img
            src={siRigImage}
            alt="logo"
            className={classes.rigImage}
          />
        </div>
      </Grid>
      <Grid className={classes.formContainer} item lg={5} md={5} sm={6} xs={12}>
        <div className={classes.root}>
          <div>
            <Snackbar
              anchorOrigin={{ vertical, horizontal }}
              open={isError}
              autoHideDuration={3000}
              onClose={() => setIsError(false)}
            >
              <Alert severity="error">{displayMessage.map((msg) => <div>{msg}</div>)}</Alert>
            </Snackbar>
            <Snackbar
              anchorOrigin={{ vertical, horizontal }}
              open={isSuccess}
              autoHideDuration={3000}
              onClose={() => setIsSuccess(false)}
            >
              <Alert severity="success">{displayMessage.map((msg) => <div>{msg}</div>)}</Alert>
            </Snackbar>
          </div>
        </div>
        <div className={classes.form}>
          <div className={classes.pubLogoContainer}>
            <img
              src={PUBLogo}
              alt="logo"
              className={classes.logoImage}
            />
          </div>
          {!forgotPasswordStatus && (
            <Tabs
              value={activeTabId}
              onChange={(e, id) => setActiveTabId(id)}
              indicatorColor="primary"
              textColor="primary"
              centered
              className={classes.tabMargin}
            >
              <Tab label="Login" classes={{ root: classes.tab }} />
            </Tabs>
          )}
          {forgotPasswordStatus && (
            <Tabs
              value={activeTabId}
              onChange={(e, id) => setActiveTabId(id)}
              indicatorColor="primary"
              textColor="primary"
              centered
              style={{ marginBottom: "10%" }}
            >
              <Tab label="Password Recovery" classes={{ root: classes.tab }} />
            </Tabs>
          )}
          {!forgotPasswordStatus && (
            <React.Fragment>
              <TextField
                id="email"
                InputProps={{
                  classes: {
                    underline: classes.textFieldUnderline,
                    input: classes.textField,
                  },
                }}
                value={loginValue}
                onChange={(e) => setLoginValue(e.target.value)}
                margin="normal"
                placeholder="Email Address"
                type="email"
                fullWidth
              />
              <TextField
                id="password"
                InputProps={{
                  classes: {
                    underline: classes.textFieldUnderline,
                    input: classes.textField,
                  },
                }}
                value={passwordValue}
                onChange={(e) => setPasswordValue(e.target.value)}
                margin="normal"
                placeholder="Password"
                type="password"
                fullWidth
                onKeyUp={(event) => {
                  if (event.key === "Enter") {
                    loginUser(
                      userDispatch,
                      loginValue,
                      passwordValue,
                      props.history,
                      setIsLoading,
                      setIsError,
                      setIsSuccess,
                      setDisplayMessage
                    );
                  }
                }}
              />
              <div>
                <p
                  style={{
                    margin: 0,
                    color: "#2d4d76",
                    fontFamily: "'Karla', sans-serif",
                    textAlign: "right",
                    cursor: "pointer",
                  }}
                  // onClick={() => forgotPasswordHandler()}
                  onClick={() => setForgotPasswordStatus(true)}
                >
                  Forgot / Reset Password
                </p>
              </div>
              <div className={classes.formButtons}>
                {isLoading ? (
                  <CircularProgress size={26} className={classes.loginLoader} />
                ) : (
                  <Button
                    disabled={
                      loginValue.length === 0 || passwordValue.length === 0
                    }
                    onClick={() =>
                      loginUser(
                        userDispatch,
                        loginValue,
                        passwordValue,
                        props.history,
                        setIsLoading,
                        setIsError,
                        setIsSuccess,
                        setDisplayMessage
                      )
                    }
                    variant="contained"
                    color="primary"
                    size="large"
                  >
                    Login
                  </Button>
                )}
              </div>
            </React.Fragment>
          )}
          {forgotPasswordStatus && (
            <React.Fragment>
              <p style={{ textAlign: "center" }}>
                Enter your E-mail address we will send you a link to reset your
                password
              </p>
              <TextField
                id="resetPasswordEmail"
                InputProps={{
                  classes: {
                    underline: classes.textFieldUnderline,
                    input: classes.textField,
                  },
                }}
                value={resetPasswordEmail}
                onChange={(e) => setResetPasswordEmail(e.target.value)}
                margin="normal"
                placeholder="Email Address"
                type="email"
                fullWidth
              />
              {/* <TextField
                id="resetPasswordOrganization"
                InputProps={{
                  classes: {
                    underline: classes.textFieldUnderline,
                    input: classes.textField,
                  },
                }}
                value={resetPasswordOrganization}
                onChange={(e) => setResetPasswordOrganization(e.target.value)}
                margin="normal"
                placeholder="Organization Name"
                type="text"
                fullWidth
              /> */}

              <div
                className={classes.formButtons}
                style={{ display: "flex", justifyContent: "center" }}
              >
                {isLoading ? (
                  <CircularProgress size={26} className={classes.loginLoader} />
                ) : (
                  <Button
                    disabled={
                      resetPasswordEmail.length === 0 //||
                      //resetPasswordOrganization.length === 0
                    }
                    onClick={() => {
                      dispatch(
                        resetUserPassword({
                          email: resetPasswordEmail
                          // organization: resetPasswordOrganization,
                        })
                      );
                      setIsLoading(true);
                      setResetPasswordEmail("");
                    }}
                    variant="contained"
                    color="primary"
                    size="large"
                  >
                    Send
                  </Button>
                )}
              </div>
              <div>
                <p
                  style={{
                    margin: 0,
                    marginTop: "10px",
                    textAlign: "center",
                    cursor: "pointer",
                    color: "#2d4d76",
                    fontFamily: "'Karla', sans-serif",
                  }}
                  onClick={() => setForgotPasswordStatus(false)}
                >
                  Back to Login
                </p>
              </div>
            </React.Fragment>
          )}
        </div>
        <Typography color="primary" className={classes.copyright}>
          &copy; Powered by SpaceAge Labs
        </Typography>
      </Grid>
    </Grid>
  );
}

export default withRouter(Login);
