import React, { Suspense } from "react";
import { Route, Switch, withRouter } from "react-router-dom";
import classnames from "classnames";
import useStyles from "./styles";
import Header from "../Header";
import Sidebar from "../Sidebar";
import { useLayoutState } from "../../context/LayoutContext";
import { CircularProgress } from "@material-ui/core";
// import {usePageTracking} from "./../../usePageTracking";

const Dashboard = React.lazy(() => import("../../pages/dashboard/Dashboard"));
const Alarms = React.lazy(() => import("../../pages/alarms/Alarms"));
const Triggers = React.lazy(() => import("../../pages/triggers/Triggers"));
const DeviceManagement = React.lazy(() =>
  import("../../pages/device-management/DeviceManagement")
);

const SiteManagement = React.lazy(() =>
  import("../../pages/siteManagement/SiteManagement")
);

const UserManagement = React.lazy(() =>
  import("../../pages/userManagement/UserManagement")
);

const SetbackManagement = React.lazy(() =>
  import("../../pages/setbackManagement/SetbackManagement")
);

const AddAsset = React.lazy(() =>
  import("../../pages/assetManagement/AddAsset")
);

const EditAsset = React.lazy(() =>
  import("../../pages/assetManagement/EditAsset")
);

const AssetManagement = React.lazy(() =>
  import("../../pages/assetManagement/AssetManagement"));

const ChangePassword = React.lazy(() => import("../../pages/changePassword/ChangePassword"));

function Layout(props) {
  var classes = useStyles();
  var layoutState = useLayoutState();
  // usePageTracking();

  let isAdmin = localStorage.getItem("isSalAdmin");

  return (
    <div className={classes.root}>
      <>
        <Header history={props.history} />
        <Sidebar />
        <div
          className={classnames(classes.content, {
            [classes.contentShift]: layoutState.isSidebarOpened,
          })}
        >
          <div className={classes.fakeToolbar} />
          <Suspense
            fallback={
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: "5%",
                }}
              >
                {" "}
                <CircularProgress size={24} />{" "}
              </div>
            }
          >
            <Switch>
              <Route path="/app/dashboard">
                <Dashboard />
              </Route>
              <Route path="/app/alarms">
                <Alarms />
              </Route>
              <Route path="/app/triggers">
                <Triggers />
              </Route>
              <Route path="/app/deviceManagement/manageDevices">
                <DeviceManagement />
              </Route>
              <Route exact path="/app/siteManagement">
                <SiteManagement />
              </Route>
              <Route path="/app/assetManagement/addAsset">
                <AddAsset />
              </Route>
              <Route path="/app/assetManagement/editAsset/:assetId">
                <EditAsset />
              </Route>
              <Route path="/app/setbackManagement">
                <SetbackManagement />
              </Route>
              <Route path="/app/assetManagement">
                <AssetManagement />
              </Route>

              <Route path="/app/changePassword">
                <ChangePassword />
              </Route>


              {isAdmin === "true" && (
                <Route path="/app/userManagement">
                  <UserManagement />
                </Route>
              )}
            </Switch>
          </Suspense>
        </div>
      </>
    </div>
  );
}

export default withRouter(Layout);
