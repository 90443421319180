import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { APIStatus } from './APIStatus'

const initialState = {
    feedRulesStatus: APIStatus.idle,
    feedRules: null,
    feedRulesError: null,
    feedIdForRules: '',
    addFeedRuleStatus: APIStatus.idle,
    addFeedRuleReceipt: null,
    addFeedRuleError: null,
    updateFeedRuleStatus: APIStatus.idle,
    updateFeedRuleReceipt: null,
    updateFeedRuleError: null,
    deleteFeedRuleStatus: APIStatus.idle,
    deleteFeedRuleReceipt: null,
    deleteFeedRuleError: null
}

export const getFeedRules = createAsyncThunk('getFeedRules', async (payload) => {
    const response = await axios.get(`/${payload.organization}/feeds/rules/?feed=${payload.feedId}`);
    return response;
})

export const addFeedRule = createAsyncThunk('addFeedRule', async (payload) => {
    const response = await axios.post(`/${payload.organization}/feeds/rules/`, payload.data);
    return response;
})

export const updateFeedRule = createAsyncThunk('updateFeedRule', async (payload) => {
    const response = await axios.patch(`/${payload.organization}/feeds/rules/${payload.data.id}`, payload.data);
    return response;
})

export const deleteFeedRule = createAsyncThunk('deleteFeedRule', async (payload) => {
    const response = await axios.delete(`/${payload.organization}/feeds/rules/${payload.id}`);
    return response;
})

const feedRulesSlice = createSlice({
    name: 'feedRules',
    initialState,
    reducers: {
        resetAddFeedRule(state, action){
            state.addFeedRuleStatus =  APIStatus.idle;
            state.addFeedRuleReceipt =  null;
            state.addFeedRuleError = null;
        },
        resetUpdateFeedRule(state, action){
            state.updateFeedRuleStatus =  APIStatus.idle;
            state.updateFeedRuleReceipt =  null;
            state.updateFeedRuleError = null;
        },
        resetDeleteFeedRule(state, action){
            state.deleteFeedRuleStatus =  APIStatus.idle;
            state.deleteFeedRuleReceipt =  null;
            state.deleteFeedRuleError = null;
        }
    },
    extraReducers: builder => {
        builder
            .addCase(getFeedRules.pending, (state, action) => {
                state.feedRulesStatus = APIStatus.loading;
            })
            .addCase(getFeedRules.fulfilled, (state, action) => {
                state.feedRulesStatus = APIStatus.loaded;
                state.feedRules = action.payload.data;
                state.feedIdForRules = action.meta.arg.id
            })
            .addCase(getFeedRules.rejected, (state, action) => {
                state.feedRulesStatus = APIStatus.failed;
                state.feedRulesError = action.error;
                state.feedIdForRules = action.meta.arg.id
            })
            .addCase(addFeedRule.pending, (state, action) => {
                state.addFeedRuleStatus = APIStatus.loading;
            })
            .addCase(addFeedRule.fulfilled, (state, action) => {
                state.addFeedRuleStatus = APIStatus.loaded;
                state.addFeedRuleReceipt = action.payload.data;
            })
            .addCase(addFeedRule.rejected, (state, action) => {
                state.addFeedRuleStatus = APIStatus.failed;
                state.addFeedRuleError = action.error;
            })
            .addCase(updateFeedRule.pending, (state, action) => {
                state.updateFeedRuleStatus = APIStatus.loading;
            })
            .addCase(updateFeedRule.fulfilled, (state, action) => {
                state.updateFeedRuleStatus = APIStatus.loaded;
                state.updateFeedRuleReceipt = action.payload.data;
            })
            .addCase(updateFeedRule.rejected, (state, action) => {
                state.updateFeedRuleStatus = APIStatus.failed;
                state.updateFeedRuleError = action.error;
            })
            .addCase(deleteFeedRule.pending, (state, action) => {
                state.deleteFeedRuleStatus = APIStatus.loading;
            })
            .addCase(deleteFeedRule.fulfilled, (state, action) => {
                state.deleteFeedRuleStatus = APIStatus.loaded;
                state.deleteFeedRuleReceipt = 'Deleted Successfully';
            })
            .addCase(deleteFeedRule.rejected, (state, action) => {
                state.deleteFeedRuleStatus = APIStatus.failed;
                state.deleteFeedRuleError = action.error;
            })
            
    }
})

export const { reset, resetAddFeedRule, resetUpdateFeedRule, resetDeleteFeedRule } = feedRulesSlice.actions

export default feedRulesSlice.reducer