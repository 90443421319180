export class CustomException extends Error {
    constructor(message) {
        super(message);
        this.name = "CustomException";
    }
}

export function getErrorFormattedMessage(errorObject) {
    let errorMessage = "An Error Occurred Please Try Again";
    if (!errorObject || typeof errorObject === 'string' || Object.keys(errorObject).length === 0) return errorMessage;
    for (const errorKey of Object.keys(errorObject)) {
        const errorValue = errorObject[errorKey];
        if (typeof errorValue === 'string') {
            const beautifiedProp = errorKey.charAt(0).toUpperCase() + errorKey.slice(1);
            errorMessage = `${beautifiedProp} - ${errorValue}`;
            break;
        }
        if (typeof errorValue === 'object') {
            if (errorValue.length === 0) continue;
            const errorStr = errorValue[0];
            const beautifiedProp = errorKey.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
            errorMessage = `${beautifiedProp} - ${errorStr}`;
            break;
        }
    }
    return errorMessage;
}

// export function formatErrorMessage(errorObject) {
//     let errorMessage = [];
//     if (!errorObject || typeof errorObject === 'string' || Object.keys(errorObject).length === 0) {
//         errorMessage.push(errorObject);
//         return errorMessage
//     };
//     for (const errorValue of Object.values(errorObject)) {
//         if (typeof errorValue === 'string') {
//             errorMessage.push(errorValue);
//         } else if (Array.isArray(errorValue) && errorValue.length > 0) {
//             errorMessage = [...errorMessage, ...errorValue];
//         }
//     }
//     return Array.from(new Set(errorMessage));
// }

export function formatErrorMessage(errorObject) {
    let errorMessage = [];
    if (!errorObject || typeof errorObject === 'string' || Object.keys(errorObject).length === 0) {
        errorMessage.push(errorObject);
        return errorMessage
    };
    for (const [errKey, errValue] of Object.entries(errorObject)) {
        if (errKey === "detail" || errKey === "non_field_errors") {
            if (typeof errValue === 'string') {
                errorMessage.push(errValue);
            } else if (Array.isArray(errValue) && errValue.length > 0) {
                errorMessage = [...errorMessage, ...errValue];
            }
        } else {
            if (typeof errValue === 'string') {
                const errKeyValue = errKey.charAt(0).toUpperCase() + errKey.slice(1);
                const msg = `${errKeyValue} - ${errValue}`;
                errorMessage.push(msg);
            } else if (Array.isArray(errValue) && errValue.length > 0) {
                const msgs = errValue.map(val => {
                    const errKeyValue = errKey.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
                    return `${errKeyValue} - ${val}`;
                });
                errorMessage = [...errorMessage, ...msgs];
            }
        }
    }
    return Array.from(new Set(errorMessage));
}

export function formatStringWithDates(textValue, dates) {
    const parsedDates = dates.map(date => new Date(date));
    const formattedDates = parsedDates.map(date => {
        const day = date.getDate().toString().padStart(2, '0');
        const month = new Intl.DateTimeFormat('en-US', { month: 'short' }).format(date);
        const year = date.getFullYear().toString();
        return `${day}${month}${year}`;
    });
    return `${textValue}-${formattedDates[0]}-${formattedDates[1]}`;
}

export function removeStringSpaces(value) {
    return value.replace(/\s/g, '');
}

export function validateCurrentPassword(currentPassword, newPassword) {
    if (!currentPassword || currentPassword === newPassword) {
        return false;
    }
    return true;
}

export function validatePasswordOne(password) {
    const minLength = 12;
    const upperCaseRegex = /[A-Z]/;
    const lowerCaseRegex = /[a-z]/;
    const digitRegex = /[0-9]/;
    const specialCharRegex = /[@]/;
    if (!password || password.length < minLength) {
        return false;
    }
    let conditionsMet = 0;
    if (upperCaseRegex.test(password)) {
        conditionsMet++;
    }
    if (lowerCaseRegex.test(password)) {
        conditionsMet++;
    }
    if (digitRegex.test(password)) {
        conditionsMet++;
    }
    if (specialCharRegex.test(password)) {
        conditionsMet++;
    }
    if (conditionsMet < 2) {
        return false;
    }
    return true;
}

export function validatePasswords(passwordOne, passwordTwo) {
    if (passwordOne !== passwordTwo) {
        return false;
    }
    return true;
}
