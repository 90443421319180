import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { APIStatus } from "./APIStatus";
import { CustomException } from "../util/util";

const initialState = {
  isLoading: "loading",
  resetPassword: null,
  resetPasswordError: null,
  resetPasswordEmail: null,
  resetPasswordEmailError: null,
  changePassword: null,
  changePasswordError: null,
  verifyEmail: null,
  verifyEmailError: null,
};

export const verifyEmailId = createAsyncThunk(
  "verifyEmailId",
  async (payload) => {
    const data = { key: payload }
    const response = await axios.post(`/verify-email`, data,)
    return response;

  }
)

export const resetUserPassword = createAsyncThunk(
  "resetUserPassword",
  async (payload) => {
    const response = await axios
      .post(`/reset-password`, payload)
      .then((res) => {
        return res;
      }).catch((error) => {
        const errorData = JSON.stringify(error.response.data);
        throw new CustomException(errorData);
      });
    return response;
  }
);


export const resetPasswordEmail = createAsyncThunk(
  "resetPasswordEmail",
  async (payload) => {
    const response = await axios.post('/reset-password-confirm', payload)
      .then((res) => {
        return res;
      }).catch((error) => {
        const errorData = JSON.stringify(error.response.data);
        throw new CustomException(errorData);
      });
    return response;
  }
);


export const changeUserPassword = createAsyncThunk("changeUserPassword",
  async (payload) => {
    const response = await axios.post(payload.url, payload.data)
      .then((response) => response)
      .catch((error) => {
        const errorData = JSON.stringify(error.response.data);
        throw new CustomException(errorData);
      });
    return response;
  });



const resetPasswordSlice = createSlice({
  name: "resetPassword",
  initialState,
  reducers: {
    reset(state, action) {
      state.resetPassword = null;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(verifyEmailId.pending, (state, action) => {
        state.isLoading = APIStatus.loading;
        state.verifyEmail = null;
        state.verifyEmailError = null;
      })
      .addCase(verifyEmailId.fulfilled, (state, action) => {
        state.isLoading = APIStatus.loaded;
        state.verifyEmail = action.payload;
        state.verifyEmailError = null;
      })
      .addCase(verifyEmailId.rejected, (state, action) => {
        state.isLoading = APIStatus.failed;
        state.verifyEmail = null;
        state.verifyEmailError = action.error;
      }).addCase(resetUserPassword.pending, (state, action) => {
        state.resetPasswordError = null;
        state.resetPassword = null;
        state.isLoading = APIStatus.loading;
      })
      .addCase(resetUserPassword.fulfilled, (state, action) => {
        state.resetPassword = action.payload.data.detail;
        state.resetPasswordError = null;
        state.isLoading = APIStatus.loaded;
      })
      .addCase(resetUserPassword.rejected, (state, action) => {
        const errorData = JSON.parse(action.error.message);
        state.resetPasswordError = errorData;
        state.isLoading = APIStatus.failed;
        state.resetPassword = null;
      })
      .addCase(resetPasswordEmail.pending, (state, action) => {
        state.isLoading = APIStatus.loading;
      })
      .addCase(resetPasswordEmail.fulfilled, (state, action) => {
        state.resetPasswordEmail = action.payload.data.message;
        state.isLoading = APIStatus.loaded;
      })
      .addCase(resetPasswordEmail.rejected, (state, action) => {
        const errorData = JSON.parse(action.error.message);
        state.resetPasswordEmailError = errorData;
        state.isLoading = APIStatus.failed;
      })
      .addCase(changeUserPassword.pending, (state, action) => {
        state.isLoading = APIStatus.loading;
        state.changePassword = null;
        state.changePasswordError = null;
      })
      .addCase(changeUserPassword.fulfilled, (state, action) => {
        state.changePassword = action.payload.data.message;
        state.changePasswordError = null;
        state.isLoading = APIStatus.loaded;
      })
      .addCase(changeUserPassword.rejected, (state, action) => {
        const errorData = JSON.parse(action.error.message);
        state.changePasswordError = errorData;
        state.changePassword = null;
        state.isLoading = APIStatus.failed;
      });

  },
});

export const { reset } = resetPasswordSlice.actions;

export default resetPasswordSlice.reducer;
