import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import moment from 'moment'
import { APIStatus } from './APIStatus'

const initialState = {
    weatherData: null,
    isLoading: 'loading'
}

export const getweatherData = createAsyncThunk('getweatherData', async (payload) => {
    var timeDiff = moment(payload.endDate).diff(moment(payload.startDate), 'days')
    let rainfallDataArr = []
    if (timeDiff > 30) {
        let noOfCalls = parseInt((timeDiff / 30) + 1)
        let startDate = moment(payload.startDate).format('DD-MM-YYYY')
        let currentDate = moment().format('DD-MM-YYYY')
        for (let i = 0; i < noOfCalls; i++) {
            let endDate = moment(startDate, 'DD-MM-YYYY').add(30, 'days').format('DD-MM-YYYY')
            if (i === (noOfCalls - 1)) {
                if (moment(currentDate).isSame(moment(endDate)) === false) {
                    endDate = moment().format('DD-MM-YYYY')
                }
            }
            rainfallDataArr.push(axios.get(`/premium/v1/past-weather.ashx?key=guv5e6mryzrefewuy4444vc5&q=${payload.postCode}&format=json&date=${startDate}&enddate=${endDate}`, { baseURL: 'https://api.worldweatheronline.com' }))
            startDate = moment(startDate, 'DD-MM-YYYY').add(30, 'days').format('DD-MM-YYYY')
        }
    } else {
        rainfallDataArr.push(axios.get(`/premium/v1/past-weather.ashx?key=guv5e6mryzrefewuy4444vc5&q=${payload.postCode}&format=json&date=${payload.startDate}&enddate=${payload.endDate}`, { baseURL: 'https://api.worldweatheronline.com' }))
    }
    const response = axios.all(rainfallDataArr).then(axios.spread((...responses) => {
        let rainFallData = []
        let responseData = {}
        for (let r = 0; r < responses.length; r++) {
            let res = responses[r];
            res.data.data.weather.forEach(rd => {
                rainFallData.push(rd)
            })
        }
        responseData = {
            data: {
                weather: rainFallData
            }
        }
        return responseData
    }))
    return response;
    // const response = await axios.get(`/premium/v1/past-weather.ashx?key=guv5e6mryzrefewuy4444vc5&q=${payload.postCode}&format=json&date=${payload.startDate}&enddate=${payload.endDate}`, { baseURL : 'https://api.worldweatheronline.com' });
    // return response;
})



const weatherSlice = createSlice({
    name: 'weather',
    initialState,
    reducers: {
        reset(state, action) {
            state.weatherData = null;
        },
    },
    extraReducers: builder => {
        builder
            .addCase(getweatherData.pending, (state, action) => {
                state.isLoading = APIStatus.loading;
            })
            .addCase(getweatherData.fulfilled, (state, action) => {
                state.isLoading = APIStatus.loaded;
                state.weatherData = action.payload.data;
            })
    }
})

export const { load } = weatherSlice.actions

export default weatherSlice.reducer