import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { APIStatus } from './APIStatus'

const initialState = {
  commentsStatus: APIStatus.idle,
  comments: null,
  commentsError: null,
  addCommentStatus: APIStatus.idle,
  addCommentReceipt: null,
  addCommentError: null,
  updateCommentStatus: APIStatus.idle,
  updateCommentReceipt: null,
  updateCommentError: null,
  deleteCommentStatus: APIStatus.idle,
  deleteCommentReceipt: null,
  deleteCommentError: null
}

export const getComments = createAsyncThunk('getComments', async (payload) => {
  const response = await axios.get(`/${payload.organization}/comments?device=${payload.id}`);
  return response;
})

export const addComment = createAsyncThunk('addComment', async (payload) => {
  const response = await axios.post(`/${payload.organization}/comments`, payload.data);
  return response;
})

export const updateComment = createAsyncThunk('updateComment', async (payload) => {
  const response = await axios.patch(`/${payload.organization}/comment/${payload.id}`, payload.data);
  return response;
})

export const deleteComment = createAsyncThunk('deleteComment', async (payload) => {
  const response = await axios.delete(`/${payload.organization}/comment/${payload.id}`);
  return response;
})

const commentsSlice = createSlice({
  name: 'comments',
  initialState,
  reducers: {
    resetUpdateComment(state) {
      state.updateCommentStatus = APIStatus.idle;
      state.updateCommentReceipt = null;
      state.updateCommentError = null;
    },
    resetAddComment(state) {
      state.addCommentStatus = APIStatus.idle;
      state.addCommentReceipt = null;
      state.addCommentError = null;
    },
    resetDeleteComment(state) {
      state.deleteCommentStatus = APIStatus.idle;
      state.deleteCommentReceipt = null;
      state.deleteCommentError = null;
    }
  },
  extraReducers: builder => {
    builder
      .addCase(getComments.pending, (state, action) => {
        state.commentsStatus = APIStatus.loading;
      })
      .addCase(getComments.fulfilled, (state, action) => {
        state.commentsStatus = APIStatus.loaded;
        state.comments = action.payload.data;
      })
      .addCase(getComments.rejected, (state, action) => {
        state.commentsStatus = APIStatus.failed;
        state.commentsError = action.error;
      })
      .addCase(addComment.pending, (state, action) => {
        state.addCommentStatus = APIStatus.loading;
      })
      .addCase(addComment.fulfilled, (state, action) => {
        state.addCommentStatus = APIStatus.loaded;
        state.addCommentReceipt = action.payload.data;
      })
      .addCase(addComment.rejected, (state, action) => {
        state.addCommentStatus = APIStatus.failed;
        state.addCommentError = action.error;
      })
      .addCase(updateComment.pending, (state, action) => {
        state.updateCommentStatus = APIStatus.loading;
      })
      .addCase(updateComment.fulfilled, (state, action) => {
        state.updateCommentStatus = APIStatus.loaded;
        state.updateCommentReceipt = action.payload.data;
      })
      .addCase(updateComment.rejected, (state, action) => {
        state.updateCommentStatus = APIStatus.failed;
        state.updateCommentError = {...action.error, id: action.meta.arg.id};
      })
      .addCase(deleteComment.pending, (state, action) => {
        state.deleteCommentStatus = APIStatus.loading;
      })
      .addCase(deleteComment.fulfilled, (state, action) => {
        state.deleteCommentStatus = APIStatus.loaded;
        state.deleteCommentReceipt = 'Deleted Successfully';
      })
      .addCase(deleteComment.rejected, (state, action) => {
        state.deleteCommentStatus = APIStatus.failed;
        state.deleteCommentError = {...action.error, id: action.meta.arg.id};
      })

  }
})

export const { reset, resetAddComment, resetDeleteComment, resetUpdateComment } = commentsSlice.actions

export default commentsSlice.reducer