import React, { useState, useEffect } from "react";
import { Drawer, IconButton, List } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import classNames from "classnames";
import * as Icons from "@material-ui/icons";
import useStyles from "./styles";
import SidebarLink from "./components/SidebarLink/SidebarLink";
import {
  useLayoutState,
  useLayoutDispatch,
  toggleSidebar,
} from "../../context/LayoutContext";

let structureList = [
  { id: 0, type: "divider" },
  { id: 1, label: "Map Dashboard", link: "/app/dashboard", icon: <Icons.Home /> },
  { id: 2, label: "Alerts", link: "/app/alarms", icon: <Icons.NotificationsActive /> },
  { id: 3, label: "Notification Settings", link: "/app/triggers", icon: <Icons.Mail /> },
  {
    id: 4,
    label: "Site Management",
    link: "/app/siteManagement",
    icon: <Icons.Map />,
  },
  {
    id: 5,
    label: "Device Summary",
    link: "/app/deviceManagement/manageDevices",
    icon: <Icons.Router />,
  },
  {
    id: 6,
    label: "User Management",
    link: "/app/userManagement",
    icon: <Icons.People />,
  },
  { id: 8, type: "divider" },
  // { id: 6, label: "Custom Dashboard", link: "/app/customDashboards", icon: <Icons.Widgets /> },
];




function Sidebar({ location }) {
  var classes = useStyles();
  var theme = useTheme();

  // global
  var { isSidebarOpened } = useLayoutState();
  var layoutDispatch = useLayoutDispatch();
  var layoutState = useLayoutState();

  // local
  var [isPermanent, setPermanent] = useState(true);
  const logoImage = `${process.env.PUBLIC_URL}/icon.png`

  useEffect(function () {
    window.addEventListener("resize", handleWindowWidthChange);
    handleWindowWidthChange();
    return function cleanup() {
      window.removeEventListener("resize", handleWindowWidthChange);
    };
  });

  let isAdmin = localStorage.getItem("isSalAdmin");
  let structure;
  if (isAdmin === "false") { 
    let tempList = [...structureList]; 
    tempList.splice(6, 1)
    structure = tempList;
  } else {
    structure = structureList;
  }
 
  return (
    <Drawer
      variant={isPermanent ? "permanent" : "temporary"}
      className={classNames(classes.drawer, {
        [classes.drawerOpen]: isSidebarOpened,
        [classes.drawerClose]: !isSidebarOpened,
      })}
      classes={{
        paper: classNames({
          [classes.drawerOpen]: isSidebarOpened,
          [classes.drawerClose]: !isSidebarOpened,
        }),
      }}
      open={isSidebarOpened}
    >
      {/* <div className={classes.toolbar} />
      <div className={classes.mobileBackButton}>
        <IconButton onClick={() => toggleSidebar(layoutDispatch)}>
          <ArrowBackIcon
            classes={{
              root: classNames(classes.headerIcon, classes.headerIconCollapse),
            }}
          />
        </IconButton>
      </div> */}
      <div className={classes.mobileBackButton}>
        <IconButton
          color="inherit"
          onClick={() => toggleSidebar(layoutDispatch)}
          className={classNames(
            classes.headerMenuButton,
            classes.headerMenuButtonCollapse,
          )}>
          {layoutState.isSidebarOpened ? (
            <Icons.Close
              classes={{
                root: classNames(
                  classes.headerIcon,
                  classes.headerIconCollapse,
                ),
              }} style={{ color: "rgb(7, 71, 166)" }}
            />
          ) : (
            <Icons.Dehaze
              classes={{
                root: classNames(
                  classes.headerIcon,
                  classes.headerIconCollapse,
                ),
              }} style={{ color: "rgb(7, 71, 166)" }}
            />
          )}
        </IconButton>

      </div>
      {isSidebarOpened === true ?
        <div style={{ marginTop: "5%" }}>
          <img style={{ marginLeft: "15%" }} alt="icon" src={logoImage} width="41" height="40" />
          {/* <Typography style={{ marginTop: "5%", float: "right", marginRight: "25%", fontFamily: "'Karla', sans-serif", fontWeight: "bold" }} >Hi, {localStorage.getItem("first_name")}</Typography> */}
        </div>
        : null}
      <List className={classes.sidebarList}>
        {structure.map((link, i) => (
          <SidebarLink
            key={i}
            location={location}
            isSidebarOpened={isSidebarOpened}
            {...link}
          />
        ))}
      </List>
    </Drawer>
  );

  // ##################################################################
  function handleWindowWidthChange() {
    var windowWidth = window.innerWidth;
    var breakpointWidth = theme.breakpoints.values.md;
    var isSmallScreen = windowWidth < breakpointWidth;

    if (isSmallScreen && isPermanent) {
      setPermanent(false);
    } else if (!isSmallScreen && !isPermanent) {
      setPermanent(true);
    }
  }
}

export default withRouter(Sidebar);
