import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { APIStatus } from "./APIStatus";

const initialState = {
  dashboardConfig: null,
  deviceFilters: null,
  deviceSummaryFilters: null,
  alarmsFilters: null,
  triggersFilters: null,
  processedDataFilters: null,
  isLoading: "loading",
  quickViewFilters: null,
};

export const getDashboardConfig = createAsyncThunk(
  "getDashboardConfig",
  async (payload) => {
    const response = await axios.get(payload.url);
    return response;
  }
);

const configurationSlice = createSlice({
  name: "configuration",
  initialState,
  reducers: {
    reset(state, action) {
      state.deviceFilters = null;
    },
    layerFilterStore(state, action) {
      state.layerFilters = action.payload;
    },
    deviceFilterStore(state, action) {
      state.deviceFilters = action.payload;
    },
    deviceSummaryFilterStore(state, action) {
      state.deviceSummaryFilters = action.payload;
    },
    alarmsFilterStore(state, action) {
      state.alarmsFilters = action.payload;
    },
    triggersFilterStore(state, action) {
      state.triggersFilters = action.payload;
    },
    quickViewFilterStore(state, action) {
      state.quickViewFilters = action.payload;
    },
    processedDataFilterStore(state, action) {
      state.processedDataFilters = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getDashboardConfig.pending, (state, action) => {
        state.isLoading = APIStatus.loading;
      })
      .addCase(getDashboardConfig.fulfilled, (state, action) => {
        state.isLoading = APIStatus.loaded;
        state.dashboardConfig = action.payload.data;
      });
  },
});

export const {
  reset,
  layerFilterStore,
  deviceFilterStore,
  deviceSummaryFilterStore,
  alarmsFilterStore,
  triggersFilterStore,
  quickViewFilterStore,
  processedDataFilterStore,
} = configurationSlice.actions;

export default configurationSlice.reducer;
