import React, { useState, useEffect } from "react";
import { AppBar, Toolbar, IconButton, Menu } from "@material-ui/core";
import {
  Menu as MenuIcon,
  MenuBook,
  Person as AccountIcon,
} from "@material-ui/icons";
import classNames from "classnames";
import useStyles from "./styles";
import { Typography } from "../Wrappers/Wrappers";
import axios from "axios";
import { useLayoutDispatch, toggleSidebar } from "../../context/LayoutContext";
import { useUserDispatch, signOut } from "../../context/UserContext";
import { useDispatch, useSelector, useStore } from "react-redux";
import { APIStatus } from "../../app/APIStatus";
import { getDashboardConfig } from "../../app/configurationSlice";
import { useHistory } from "react-router-dom";

export default function Header(props) {
  const history = useHistory();
  var classes = useStyles();
  const [profileMenu, setProfileMenu] = useState(null);
  const username = localStorage.getItem("email");
  const organization = localStorage.getItem("organization");
  const [dashboardName, setDashboardName] = useState("Geofence");
  const [devicesHealth, setdevicesHealth] = useState({
    total: 0,
    active: 0,
    inactive: 0,
    alert: 0,
    unsubscribed: 0,
  });

  const dispatch = useDispatch();
  var layoutDispatch = useLayoutDispatch();
  var userDispatch = useUserDispatch();
  const store = useStore();

  const dashboardSettings = useSelector(
    (state) => state.configurationSlice.dashboardConfig
  );

  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error && error.response && error.response.status === 401) {
        alert("Session Expired, Please login again");
        performSignOut();
      } else {
        return new Promise((resolve, reject) => {
          reject(error);
        });
      }
    }
  );

  useEffect(() => {
    let healthData = store.getState().devicesSlice.devicesHealth;
    setdevicesHealth(healthData);
  }, [store]);

  useEffect(() => {
    if (dashboardSettings === null && organization) {
      let payloadUrl = `organizations/${organization}`;
      dispatch(getDashboardConfig({ url: payloadUrl }));
    }
    if (dashboardSettings !== null) {
      if (
        dashboardSettings.meta_data &&
        dashboardSettings.meta_data.custom_config
      ) {
        let config = dashboardSettings.meta_data.custom_config;
        localStorage.setItem("dashboard_config", JSON.stringify(config));
        if (config.name) {
          setDashboardName(config.name);
        }
      }
    }
  }, [dispatch, organization, dashboardSettings]);

  // useEffect(() => {
  //   if (devicesHealthData === null && organization) {
  //     let devicesHealthApi = `/${organization}/devices-health/`
  //     dispatch(getDevicesHealth({url : devicesHealthApi}))
  //   } else if(devicesHealthData !== null){
  //     setdevicesHealth(devicesHealthData)
  //   }
  // }, [dispatch, organization, devicesHealthData])

  const performSignOut = () => {
    dispatch({ type: APIStatus.closeSession });
    signOut(userDispatch, props.history);
  };

  const changePasswordHandler = () => {
    history.push({
      pathname: `/app/changePassword`,
    });
    setProfileMenu(null);
  };

  const userManualRedirection = () => {
    let userManualLink = `https://drive.google.com/file/d/1GNb7wp7CBhMfltFjoKqYSO_EyMsNcRTQ/view?usp=sharing`;
    window.open(userManualLink);
  };

  return (
    <AppBar position="fixed" className={classes.appBar}>
      <Toolbar className={classes.toolbar}>
        <IconButton
          color="inherit"
          onClick={() => toggleSidebar(layoutDispatch)}
          className={classNames(
            classes.headerMenuButton,
            classes.headerMenuButtonCollapse
          )}
        >
          <MenuIcon
            classes={{
              root: classNames(classes.headerIcon, classes.headerIconCollapse),
            }}
          />
        </IconButton>
        <div
          style={{
            textAlign: "center",
            flexGrow: 1,
            paddingLeft: devicesHealth !== null ? 150 : 0,
          }}
        >
          <Typography variant="h5" weight="bold" className={classes.logotype}>
            {dashboardName}
          </Typography>
          <div style={{ fontSize: "12px" }}>
            Powered by{" "}
            <a
              style={{
                textDecoration: "none",
                color: "#fff",
                fontWeight: "bold",
              }}
              href="https://www.spaceage-labs.com/"
            >
              SpaceAge Labs
            </a>
          </div>
        </div>
        {/* {devicesHealth !== null && <div style={{ display : 'flex'}}>
          <Tooltip title="No. of Active Devices" placement="bottom">
            <div className={classes.deviceCount} style={{ backgroundColor: '#00CCCC' }}>{devicesHealth.active}</div>
          </Tooltip>
          <Tooltip title="No. of Alert Devices" placement="bottom">
            <div className={classes.deviceCount} style={{ backgroundColor: '#F55e84' }}>{devicesHealth.alert}</div>
          </Tooltip>
          <Tooltip title="No. of InActive Devices" placement="bottom">
            <div className={classes.deviceCount} style={{ backgroundColor: '#DFE1E6' }}>{devicesHealth.inactive}</div>
          </Tooltip>
        </div>} */}

        <IconButton
          aria-haspopup="true"
          color="inherit"
          className={classes.headerMenuButton}
          aria-controls="profile-menu"
          onClick={() => userManualRedirection()}
        >
          <MenuBook classes={{ root: classes.headerIcon }} />
        </IconButton>

        <IconButton
          aria-haspopup="true"
          color="inherit"
          className={classes.headerMenuButton}
          aria-controls="profile-menu"
          onClick={(e) => setProfileMenu(e.currentTarget)}
        >
          <AccountIcon classes={{ root: classes.headerIcon }} />
        </IconButton>

        <Menu
          id="profile-menu"
          open={Boolean(profileMenu)}
          anchorEl={profileMenu}
          onClose={() => setProfileMenu(null)}
          className={classes.headerMenu}
          classes={{ paper: classes.profileMenu }}
          disableAutoFocusItem
        >
          <div className={classes.profileMenuUser}>
            <Typography
              className={classes.profileUserFont}
              style={{}}
              weight="medium"
            >
              {username}
            </Typography>
          </div>

          <div className={classes.profileMenuUser}>
            <div className={classes.profileMenuLinkWrapper}>
              <Typography
                className={classes.profileMenuLink}
                color="primary"
                weight="bold"
                onClick={() => changePasswordHandler()}
              >
                Change Password
              </Typography>
              <Typography
                className={classes.profileMenuLink}
                color="primary"
                weight="bold"
                onClick={performSignOut}
              >
                Sign Out
              </Typography>
            </div>
          </div>
        </Menu>
      </Toolbar>
    </AppBar>
  );
}
